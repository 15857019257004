.spinner-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 400px; /* position of spinner height */
}

.spinner {
  border: 8px solid rgba(228, 0, 69, 0.1); /* Light gray border */
  border-top: 8px solid #E40045; /* Darker color for the top */
  border-radius: 50%; /* Makes it circular */
  width: 40px; /* Spinner size */
  height: 40px; /* Spinner size */
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Starting rotation */
  }
  100% {
    transform: rotate(360deg); /* Complete rotation */
  }
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border: 0.25em solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

