.headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    padding: 0px 0px;
    font-weight: bold;
    font-size: 10px;
    text-align: left;
    color: rgb(48, 60, 73);
  }
  
.logo-container {
    display: flex;
    align-items: center;
    width: 320px;
    height: 47px;
}
  
.man-logo {
    width:140px;
    left:0;
}

.logo-title {
    font-size: 15px;
    padding: 28px;
}

.nav-items{
    border: 1px;
    display: flex;
    flex-direction: row;
  
  }
  
.nav-items > ul{
    display: flex;
    list-style-type: none;
    justify-content: center;

}
  
.man-logo1{
    margin-left: 4px;
  }

.logout{
    margin: 10px;
    display: flex;
    flex-direction: row;

}

.logout-text{
    font-family: MAN EUROPE_Bold;
    font-size: 10px;
}

.MAN_info{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;
}

.MAN_info_text {
  margin-left: 5px;
  font-size: 16px;
}

.filters {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
 

@font-face {
    font-family: MAN EUROPE_regular;
    src: url(./typography/ManEurope_Regular.ttf);
}

@font-face {
    font-family: MAN EUROPE_Bold;
    src: url(./typography/MAN_Global-BoldCondensed.ttf);
}
  