/* Modal.css */

.modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 40px;
    border-radius: 8px;
    font-family: 'MAN EUROPE_regular';
    font-size: 16;
    color: white;
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal.show {
    display: block;
  }
  