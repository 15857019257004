.App {
  text-align: center;
  background-color: 'rgb(248, 248, 248)';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App_title {
  font-family: 'MAN EUROPE_Bold';
  font-size: 18px;
  width: 500px;
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid rgba(234, 237, 243, 1);
  height: 85px;
  padding: 0px 24px;
  border-width: 0px 0px 2px;
}

.MAN_alert_div {
  display: flex;
  font-family: 'MAN EUROPE_Bold';
  font-size: 18px;
  background-color: rgba(255, 176, 176, 1);
  height: 14px;
  padding: 12px;
  gap: 8px;
}

.MAN_info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
}

.MAN_info_text {
  margin-left: 5px;
  font-size: 16px;
}

.alert_icon {
  height: 18px;
  width: 18px;
}

.needHelp {
  margin: 10px;
  display: flex;
  flex-direction: row;
}

.logo-container {
  display: flex;
  align-items: left;
  width: 320px;
  height: 47px;
}

.help-button{
  background-color: white;
  border: none;
  font-size: 16px;
  font-family: 'MAN EUROPE_Bold';
  color: rgb(48, 60, 73);
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgb(248, 248, 248);
}

.popover {
  margin-top: 8px;
  margin-right: 14px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 2000; /* Higher than Snackbar */
}

.popover-content {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 10px;
  font-family: 'MAN EUROPE_regular';
  font-size: 14px;
  color: 'rgb(48, 60, 73)';
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  transform: rotate(310deg); /* Create an arrow */
  top: 20px; /* Adjust to position from the top */
  left: -5px; /* Adjust to position on the right side */
  margin-top: -5px;

  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* gridTemplateColumns: 'repeat(3, 1fr)': Defines three equal-width columns. */
  gap: 5px;
  justify-items: center;
}

.button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counts_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.counts_container_wrapper {
  flex: 0 0 25%;
  margin: 10px;
}

.counts_container_content {
  color: rgb(48, 60, 73);
  width: 100%;
}

.divider {
  border-color: #CBD3DC;
}

.KPI_main_div {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
}

.outer_div_title_onTop_of_KPI {
  display: flex;
  overflow-x: none;
  background-color: white;
  height: 40px;
  justify-content: left;
}

.title_onTop_of_KPI {
  font-family: 'MAN EUROPE_Bold';
  font-size: 18px;
  margin-top: 10px;
  color: rgb(48, 60, 73);
  margin-left: 17px; 
}

.plot_div {
  display: flex;
  background-color: white;
  justify-content: space-between;
  flex-wrap: wrap;
}

.no_vehicles_message {
  margin-top: 200px;
  margin-bottom: 200px;
  font-family: 'MAN EUROPE_regular';
}

.error_message {
  margin-top: 200px;
  margin-bottom: 200px;
  font-family: 'MAN EUROPE_regular';
}

.text_below_buttons {
  font-family: 'MAN EUROPE_Bold';
  font-size: 14px;
  color: rgb(48, 60, 73);
}

/* gridTemplateColumns: 'repeat(3, 1fr)': Defines three equal-width columns. */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: MAN EUROPE_regular;
  src: url(./assets/typography/ManEurope_Regular.ttf);
}

@font-face {
  font-family: MAN EUROPE_Bold;
  src: url(./assets/typography/MAN_Global-BoldCondensed.ttf);
}
/* App.css */
.full-screen-modal .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.full-screen-modal .modal-content p {
  font-size: 24px;
  color: white;
  text-align: center;
  padding: 20px;
  background-color: #fff; /* Content background color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
